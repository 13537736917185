<template>
  <div>
    <people-details-drawer></people-details-drawer>
  </div>
</template>

<script>
const PeopleDetailsDrawer = () => import("../drawers/PeopleDetailsDrawer.vue");
export default {
  components: {
    PeopleDetailsDrawer
  }
};
</script>
